import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SideBarView from "../components/SideBarView"
import dateFormat from "dateformat"
import { CodeBlockView } from "../components/HelperComponents"
import { Typography, Grid } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})


// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function(dowOffset) {
  dowOffset = typeof (dowOffset) == "number" ? dowOffset : 0 //default dowOffset to zero
  const newYear = new Date(this.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = (day >= 0 ? day : day + 7)
  const daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
  let weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      const nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

const programmingRoutines = [
  {
    title: "Microsoft Excel / LibreOffice Calc",
    code: `=TODAY()-DATE(YEAR(TODAY()),1,0)
// or, for any date entered in cell A1, calculate the corresponding day-number in that date’s year:
=A1-DATE(YEAR(A1),1,0)`,
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Calculate today's day-number, starting from the day
      before Jan 1, so that Jan 1 is day 1.</p>
  },
  {
    title: "Google Docs Spreadsheet",
    code: "=DATEDIF(CONCAT(\"1-1-\";year(now()));today();\"D\")+1",
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Calculates the difference between Jan 1 and today
      (=days past) then add 1 for today's daynumber. (Your date format (1-1-year) may be different)</p>
  },
  {
    title: "LibreOffice Calc",
    code: `=ROUNDDOWN(DAYS(NOW(),DATE(YEAR(NOW()),1,1))) + 1`,
    language: "sql",
    description: null
  },
  {
    title: "PHP",
    code: `$dayNumber = date("z") + 1;
    date("z", epoch) + 1`,
    language: "php",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>You can use an epoch to find other day numbers<br/>date("z")
      starts counting from 0 (0 through 365)!</p>
  },
  {
    title: "Python",
    code: "from datetime import datetime\n" +
      "day_of_year = datetime.now().timetuple().tm_yday",
    language: "python",
    description: null
  },
  {
    title: "PERL",
    code: `use Time::Piece;
my $day_of_year = localtime->yday + 1;

# ... or ...
my $day_of_year = (localtime)[7] +1;

# ... or (if you really want to use POSIX) ...
use POSIX;
my $day_of_year = POSIX::strftime("%j", time);`,
    language: "perl",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Replace time with other epochs for other days.</p>
  },
  {
    title: "MySQL",
    code: `SELECT DAYOFYEAR(NOW())
    // or
    SELECT DAYOFYEAR('2022-02-20');`,
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Day number between 1 and 366. Replace now() with
      other dates.</p>
  },
  {
    title: "Oracle",
    code: `select to_char(sysdate, 'DDD') from dual
select to_char(to_date('2022-02-20','YYYY-MM-DD'), 'DDD') from dual`,
    language: "sql",
    description: null
  },
  {
    title: "Delphi",
    code: `using DateUtils, SysUtils;
DayOfTheYear(Date);
`,
    language: "delphi",
    description: null
  },
  {
    title: "Microsoft Access",
    code: `DatePart("y", Now())`,
    language: "sql",
    description: null
  },
  {
    title: "Visual Basic (VB.NET)",
    code: `Dim dayOfYear As Integer = DateTime.Now.DayOfYear`,
    language: "sql",
    description: null
  },
  {
    title: "JavaScript",
    code: `var today = new Date();
Math.ceil((today - new Date(today.getFullYear(),0,1)) / 86400000);
Date.prototype.getDOY = function() {
  var onejan = new Date(this.getFullYear(),0,1);
  return Math.ceil((this - onejan) / 86400000);
}
var today = new Date();
var daynum = today.getDOY();`,
    language: "javascript",
    description: null
  },
  {
    title: "Java",
    code: `LocalDate.now().getDayOfYear();`,
    language: "java",
    description: null
  },
  {
    title: "Unix/Linux",
    code: `date +%j`,
    language: "shell",
    description: null
  },
  {
    title: "ColdFusion",
    code: `#dayofyear(now())# `,
    language: "ColdFusion",
    description: null
  },
  {
    title: "Objective C",
    code: `int currentDay;
dateFormatter = [[NSDateFormatter alloc] init];
[dateFormatter setDateFormat:@"D"];
date = [NSDate date];
currentDay = [[dateFormatter stringFromDate:date] intValue];`,
    language: "swift",
    description: null
  },
  {
    title: "C#",
    code: "int iDayOfYear = System.DateTime.UtcNow.DayOfYear;",
    language: "c#",
    description: null
  },
  {
    title: "R",
    code: `format(Sys.Date(), "%j")`,
    language: "r",
    description: null
  },
  {
    title: "Ruby",
    code: `time = Time.new
puts time.yday`,
    language: "ruby",
    description: null
  },
  {
    title: "Windows PowerShell",
    code: `$DayOfYear = (Get-Date).DayofYear
Write-Host $DayOfYear`,
    language: "bash",
    description: null
  },
  {
    title: "LiveCode",
    code: `on mouseUp
put "January 1," && the last word of the long date into firstDayofYear --append current year
convert firstDayofYear to seconds -- from Jan 1, 1970 to first day of this year
put the long date into currentDay
convert currentDay to seconds -- from Jan 1, 1970 GMT to today
put currentDay - firstDayofYear into totalSeconds
answer the round of (totalSeconds / (60*60*24)) + 1 --display total days in dialog box
end mouseUp
// Or
on mouseUp
answer DayOfYear()
end mouseUp

function DayOfYear
put the short date into currentDate
convert currentDate to dateItems --list of date elements separated by commas
put item 1 of currentDate into year
put item 2 of currentDate into month
put item 3 of currentDate into day
put floor (275 * month / 9) into N1
put floor ( (month + 9) / 12) into N2
put (1 + floor ( (year - 4 * floor (year / 4) + 2) / 3) ) into N3
put N1 - (N2 * N3) + day - 30 into N
return N
end DayOfYear

function floor pNumber -- LiveCode has no built-in floor() function
put Round (pNumber) into theInteger
if theInteger > pNumber then
put theInteger - 1 into theInteger
end if
return theInteger
end floor`,
    language: "livecode",
    description: null
  },
  {
    title: "T-SQL (Transact-SQL)",
    code: `SELECT DATEPART(DAYOFYEAR, SYSDATETIME())
    // Or
    SELECT datediff(day,CAST(datepart(year,getdate()) AS CHAR(4)) + '-01-01',getdate()+1)
  AS number_of_today`,
    language: "sql",
    description: null
  },
  {
    title: "Go (golang)",
    code: `day := time.Now().YearDay()`,
    language: "go",
    description: null
  },
  {
    title: "Matlab",
    code: `dayNumber = today-datenum(['1-Jan-' year(today)])+1`,
    language: "sql",
    description: null
  }
]

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const DayNumbers = () => {
  const year = (new Date()).getFullYear()
  const today = new Date(year, (new Date()).getMonth(), (new Date()).getDate())
  const startOfYear = new Date(year, 0, 1)
  const dayNumber = (today.getTime() - startOfYear.getTime()) / 60 / 60 / 24 / 1000 + 1
  const totalDaysInYear = ((new Date(2022, 11, 31)).getTime() - (new Date(2022, 0, 1)).getTime()) / 60 / 60 / 24 / 1000 + 1
  return (
    <Container>
      <CustomHeader title={`What's the Current /   Day Number?`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Today {dateFormat(new Date(), "dddd, mmmm dS, yyyy")} is ...
          </Typography>
          <div style={{
            width: "100%",
            height: "100px",
            border: "1px solid",
            borderRadius: "5px",
            textAlign: "center",
            margin: "auto",
            padding: "25px 0 25px 0"
          }}>
            <Typography variant={`h5`}><b>Day {String(dayNumber).padStart(2, "0")}</b></Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>
              Day of the year is a number between 1 and {totalDaysInYear} (in {year}), January 1 is day 1. <br/>
              After today {totalDaysInYear - dayNumber} days are remaining in this year.<br/>
              This page uses the ISO-8601 ordinal date format.<br/>
              <br/>
              There is also another less-used format: the 'ISO day of year' numbers, this is a number between 1 and 371,
              day 1 of the year is Monday of the first ISO week (where the first Thursday of the new year is in week 1).<br/>
            </p>
            <p>Lists of day numbers by year :&nbsp;
              <b>
                <Link to={`/days/${year - 1}`}>{year - 1}</Link> -&nbsp;
                <Link sx={{ fontSize: "21px" }} to={`/days/${year}`}>{year}</Link> -&nbsp;
                <Link to={`/days/${year + 1}`}>{year + 1}</Link>
              </b>
            </p>
          </div>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Programming routines
          </Typography>
          <div>
            {programmingRoutines.map(programmingRoutineView)}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default DayNumbers

export const Head = () => {
  const siteMetadata = {
    description: "day number by date in a year will be shown with programming explanation in multiple languages",
    description2: "",
    keywordsContent: "day, number, programming, code snippets, c, php, java, javascript"
  }
  return (
    <Seo
      title="Unix / Epoch - Display day number in year and code snippets for conversion"
      canonicalUrl={"https://www.epochconverter.io/day-numbers/"}
      siteMetadata={siteMetadata}
    />
  )
}
